import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    main: '#006CB2',
    contrast: '#D8D8D8',
    secondary: '#FFFFFF',
    gray: '#444444',
    light_gray: '#E5E5E5',
    gradient: 'linear-gradient(90deg, rgba(0,67,110,1) 0%, rgba(0,108,178,1) 100%)',
    gradient_background: 'rgb(0,67,110)',
    border: '#707070',
    light_border: '#D8D8D8',

    text: {
      main: '#006CB2',
      contrast: '#0083C9',
      blue: '#0077BE',
      secondary: '#444444',
      light: '#FFFFFF',
      gray: '#6E6E6E'
    },
  },

  font: {
    family: {
      main: 'Montserrat, sans-serif',
      secondary: 'Montserrat, sans-serif',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      ms: '18px',
      xm: '20px',
      l: '24px',
      xl: '30px',
      xxl: '40px',
      xxml: '60px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 700,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }
  
  .color {
    &-background {
      &-main { background-color: ${(props) => props.theme.color.main} }
      &-contrast { background-color: ${(props) => props.theme.color.contrast} }
      &-secondary { background-color: ${(props) => props.theme.color.secondary} }
    }
    
    &-text {
      &-main { color: ${(props) => props.theme.color.text.main} }
      &-secondary { color: ${(props) => props.theme.color.text.secondary} }
      &-contrast { color: ${props => props.theme.color.text.contrast} }
      &-light { color: ${props => props.theme.color.text.light} }
      &-blue { color: ${props => props.theme.color.text.blue} }
      &-gray { color: ${props => props.theme.color.text.gray} }
    }
  }
  
  .font {
    &-family {
      &-main { font-family: ${(props) => props.theme.font.family.main} }
      &-secondary { font-family: ${(props) => props.theme.font.family.secondary} }
    }
    
    &-size {
      &-xxs { font-size: ${(props) => props.theme.font.size.xxs} }
      &-xs { font-size: ${(props) => props.theme.font.size.xs} }
      &-s { font-size: ${(props) => props.theme.font.size.s} }
      &-sm { font-size: ${(props) => props.theme.font.size.sm} }
      &-m { font-size: ${(props) => props.theme.font.size.m} }
      &-xm { font-size: ${(props) => props.theme.font.size.xm} }
      &-l { font-size: ${(props) => props.theme.font.size.l} }
      &-xl { font-size: ${(props) => props.theme.font.size.xl} }
      &-xxl { font-size: ${(props) => props.theme.font.size.xxl} }
      &-xxml { font-size: ${props => props.theme.font.size.xxml} }
      &-xxxl { font-size: ${(props) => props.theme.font.size.xxxl} }
    }
    
    &-weight {
      &-xs { font-weight: ${(props) => props.theme.font.weight.xs} }
      &-s { font-weight: ${(props) => props.theme.font.weight.s} }
      &-m { font-weight: ${(props) => props.theme.font.weight.m} }
      &-l { font-weight: ${(props) => props.theme.font.weight.l} }
      &-xl { font-weight: ${(props) => props.theme.font.weight.xl} }
    }
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme